import React from "react"
import { graphql } from "gatsby"

import Root from "../components/Root"
import Meta from "../components/Meta"
import Blades from "../components/Blades"

export const query = graphql`
  query getCharacter($id: String) {
    posts: allDatoCmsPost(
      filter: { characters: { elemMatch: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          id
        }
      }
    }
    character: datoCmsCharacter(id: { eq: $id }) {
      id
      meta {
        firstPublishedAt
      }
      seo {
        description
        title
        twitterCard
        image {
          url(imgixParams: { w: "1200", h: "630", fit: "crop" })
        }
      }
      name
      blades {
        ... on DatoCmsCallToActionImage {
          scrollToBladeId
          model {
            apiKey
          }
          id
          heading
          description
          backgroundImage {
            fluid(imgixParams: { w: "2700", h: "1140", fit: "crop" }) {
              aspectRatio
              base64
              height
              sizes
              src
              srcSet
              width
            }
          }
        }
        ... on DatoCmsCallToActionImageAlt {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          description
          showButton
          buttonLabel
          isExternal
          externalLink
          link {
            ... on DatoCmsPage {
              model {
                apiKey
              }
              slug
            }
            ... on DatoCmsCharacter {
              model {
                apiKey
              }
              slug
            }
          }
          backgroundImage {
            fluid(imgixParams: { w: "2700", h: "1140", fit: "crop" }) {
              aspectRatio
              base64
              height
              sizes
              src
              srcSet
              width
            }
          }
        }

        ... on DatoCmsCarouselCharacter {
          scrollToBladeId
          model {
            apiKey
          }
          backgroundImage {
            fluid(imgixParams: { w: "2700", h: "1140", fit: "crop" }) {
              aspectRatio
              base64
              height
              sizes
              src
              srcSet
              width
            }
          }
          showAll
          characters {
            name
            description
            position
            comingSoon
            logo {
              gatsbyImageData(placeholder: NONE)
            }
            thumbnail {
              gatsbyImageData
            }
            featuredImage {
              gatsbyImageData
            }
          }
        }
        ... on DatoCmsCarouselFeaturedCharacter {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          showAll
          characters {
            name
            slug
            description
            position
            comingSoon
            classType {
              name
              colour {
                hex
                red
                green
                blue
              }
            }
            logo {
              gatsbyImageData(placeholder: NONE)
            }
            thumbnail {
              gatsbyImageData
            }
            featuredImage {
              gatsbyImageData
            }
            featuredImageSmall: featuredImage {
              gatsbyImageData(
                placeholder: BLURRED
                imgixParams: { w: "600", h: "600" }
              )
            }
          }
        }
        ... on DatoCmsCarouselDownload {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          downloads {
            title
            file {
              url
              filename
            }
            thumbnail {
              gatsbyImageData(
                placeholder: BLURRED
                imgixParams: { w: "300", h: "300", fit: "crop" }
              )
            }
            tags
          }
          filterLabel
          backgroundImage {
            fluid(imgixParams: { w: "2700", h: "1140", fit: "crop" }) {
              aspectRatio
              base64
              height
              sizes
              src
              srcSet
              width
            }
          }
        }
        ... on DatoCmsCarouselArena {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          arenas {
            name
            description
            featuredImage {
              gatsbyImageData(
                placeholder: BLURRED
                imgixParams: { w: "1020", h: "1080", fit: "crop" }
              )
            }
            backgroundImage: featuredImage {
              fluid(imgixParams: { w: "2700", h: "1140", fit: "crop" }) {
                aspectRatio
                base64
                height
                sizes
                src
                srcSet
                width
              }
            }
            informationNode {
              image {
                gatsbyImageData(
                  placeholder: BLURRED
                  imgixParams: { w: "520", h: "300", fit: "crop" }
                )
              }
              heading
              description
              nodePosition
            }
          }
        }
        ... on DatoCmsCarouselClassType {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          classTypes {
            type: classType
            description
            icon {
              url
            }
            # thumbnail {
            #     gatsbyImageData(
            #         placeholder: BLURRED
            #         imgixParams: { w: "600", h: "350", fit: "crop" }
            #     )
            # }
            video {
              url
            }
            assets {
              video {
                mp4Url
              }
              thumbnail: gatsbyImageData(
                placeholder: BLURRED
                imgixParams: { w: "600", h: "350", fit: "crop" }
              )
            }
          }
        }
        ... on DatoCmsColumnsThumbnail {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          columns {
            heading
            description
            image {
              gatsbyImageData(
                placeholder: BLURRED
                imgixParams: { w: "865", h: "490", fit: "crop" }
              )
            }
            video {
              url
            }
            aurebesh {
              url
            }
            aurebeshAnimation
          }
        }
        ... on DatoCmsHeroVideo {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          description
          video {
            title
            url
            width
            height
            provider
            providerUid
            thumbnailUrl
          }
          mobileBackgroundImage: backgroundImage {
            fluid(
              maxWidth: 600
              maxHeight: 800
              imgixParams: { maxW: 600, maxH: 800, fit: "crop" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
          backgroundImage {
            fluid(
              maxWidth: 2800
              maxHeight: 1400
              imgixParams: { maxW: 2800, maxH: 1400, fit: "crop" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsHeroText {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          description
          mobileBackgroundImage: backgroundImage {
            fluid(
              maxWidth: 600
              maxHeight: 800
              imgixParams: { maxW: 600, maxH: 800, fit: "crop" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
          backgroundImage {
            fluid(
              maxWidth: 2800
              maxHeight: 1400
              imgixParams: { maxW: 2800, maxH: 1400, fit: "crop" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
          leftImage {
            gatsbyImageData(placeholder: BLURRED)
          }
          rightImage {
            gatsbyImageData(placeholder: BLURRED)
          }
          small
        }
        ... on DatoCmsHeroNews {
          scrollToBladeId
          model {
            apiKey
          }
          buttonLabel
          posts {
            id
            originalId
            slug
            locale
            title
            excerpt
            position
            meta {
              firstPublishedAt
            }
            tags
            featuredImage {
              fluid {
                aspectRatio
                base64
                height
                sizes
                src
                srcSet
                width
              }
            }
            characters {
              id
            }
            thumbnail: featuredImage {
              gatsbyImageData(
                placeholder: BLURRED
                imgixParams: { w: "400", h: "400", fit: "crop" }
              )
            }
            thumbnailSmall: featuredImage {
              gatsbyImageData(
                placeholder: BLURRED
                imgixParams: { w: "400", h: "200", fit: "crop" }
              )
            }
          }
        }
        ... on DatoCmsHeroCharacter {
          scrollToBladeId
          model {
            apiKey
          }
          character {
            id
            name
            logo {
              gatsbyImageData(placeholder: NONE)
            }
            thumbnail {
              gatsbyImageData
            }
            featuredImage {
              gatsbyImageData
            }
            animation {
              url
            }
            animationFallbackImage {
              gatsbyImageData
            }
          }
          mobileBackgroundImage: backgroundImage {
            fluid(
              maxWidth: 600
              maxHeight: 800
              imgixParams: { maxW: 600, maxH: 800, fit: "crop" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
          backgroundImage {
            fluid(
              maxWidth: 2800
              maxHeight: 1400
              imgixParams: { maxW: 2800, maxH: 1400, fit: "crop" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsIncentive {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          description
          subheading
          milestones {
            milestone
            heading
            thumbnail {
              gatsbyImageData(placeholder: NONE)
            }
            lockedStatus
            percentage
          }
          bottomImage {
            gatsbyImageData(placeholder: NONE)
          }
          backgroundImage {
            fluid(imgixParams: { w: "2700", h: "1140", fit: "crop" }) {
              aspectRatio
              base64
              height
              sizes
              src
              srcSet
              width
            }
          }
          shareHeading
        }
        ... on DatoCmsLatestNews {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          buttonLabel
          backgroundImage {
            gatsbyImageData
          }
        }
        ... on DatoCmsFeaturedNews {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          posts {
            id
            originalId
            slug
            locale
            title
            position
            meta {
              firstPublishedAt
            }
            tags
            featuredImage {
              fluid {
                aspectRatio
                base64
                height
                sizes
                src
                srcSet
                width
              }
            }
            characters {
              id
            }
            thumbnail: featuredImage {
              gatsbyImageData(
                placeholder: BLURRED
                imgixParams: { w: "400", h: "400", fit: "crop" }
              )
            }
            thumbnailSmall: featuredImage {
              gatsbyImageData(
                placeholder: BLURRED
                imgixParams: { w: "400", h: "200", fit: "crop" }
              )
            }
          }
        }
        ... on DatoCmsPanelImage {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          description
          backgroundImage {
            fluid(imgixParams: { w: "2700", h: "1140", fit: "crop" }) {
              aspectRatio
              base64
              height
              sizes
              src
              srcSet
              width
            }
          }
        }
        ... on DatoCmsPanelVideo {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          description
          video {
            title
            url
            width
            height
            provider
            providerUid
            thumbnailUrl
          }
          thumbnail {
            gatsbyImageData
          }
          showButton
          link {
            slug
          }
          buttonText
          backgroundImage {
            fluid(imgixParams: { w: "2700", h: "1140", fit: "crop" }) {
              aspectRatio
              base64
              height
              sizes
              src
              srcSet
              width
            }
            gatsbyImageData(
              placeholder: BLURRED
              imgixParams: { w: "3000", h: "1500", fit: "crop", q: 100 }
            )
          }
        }
        ... on DatoCmsPanelThumbnail {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          headingIcon {
            gatsbyImageData(
              placeholder: BLURRED
              imgixParams: { w: "300", h: "300", fit: "fillmax" }
            )
          }
          subheading
          description
          thumbnailSubheading
          thumbnail {
            gatsbyImageData(
              placeholder: BLURRED
              imgixParams: { w: "684", h: "249", fit: "crop" }
            )
          }
          thumbnailHeading
          thumbnailDescription
        }
        ... on DatoCmsPreRegistration {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          description
          contentCreatorLabel
          yesLabel
          noLabel
          submitLabel
          privacyLabel
          referrerOptions
          platformPreferenceOptions
          genreOptions
          frequencyOptions
          loadingMessage
          successMessage
          invalidMessage
          alreadyRegisteredMessage
          mustOptInMessage
          invalidEmailAddressMessage
          invalidPlatformMessage
          genericErrorMessage
        }
        ... on DatoCmsGridVideo {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          perPage
          buttonLabel
          videos {
            heading
            video {
              title
              url
              width
              height
              provider
              providerUid
              thumbnailUrl
            }
            thumbnail {
              gatsbyImageData
            }
          }
        }
        ... on DatoCmsGridSocial {
          scrollToBladeId
          model {
            apiKey
          }
        }
        ... on DatoCmsGridCharacter {
          scrollToBladeId
          model {
            apiKey
          }
          perPage
        }
        ... on DatoCmsGridNews {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          perPage
        }
        ... on DatoCmsGridPerk {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          body
          images {
            gatsbyImageData(
              placeholder: BLURRED
              imgixParams: { w: "400", h: "300", fit: "crop" }
            )
            filename
            url
            title
          }
          background {
            fluid(imgixParams: { w: "2700", h: "1140", fit: "crop" }) {
              aspectRatio
              base64
              height
              sizes
              src
              srcSet
              width
            }
          }
        }

        ... on DatoCmsGalleryMediaBlock {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          assets {
            tags
            image {
              sm: gatsbyImageData(
                placeholder: BLURRED
                imgixParams: { w: "400", h: "400", fit: "crop" }
              )
              lg: gatsbyImageData(
                placeholder: BLURRED
                imgixParams: { w: "600", h: "600", fit: "crop" }
              )
              wide: gatsbyImageData(
                placeholder: BLURRED
                imgixParams: { w: "600", h: "400", fit: "crop" }
              )
              full: fluid(imgixParams: { w: "2700", h: "1140", fit: "crop" }) {
                aspectRatio
                base64
                height
                sizes
                src
                srcSet
                width
              }
              filename
              url
            }
            description
          }
          filterLabel
        }

        ... on DatoCmsCharacterAbility {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          description
          mobileBackgroundImage: backgroundImage {
            fluid(
              maxWidth: 600
              maxHeight: 800
              imgixParams: { maxW: 600, maxH: 800, fit: "crop" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
          backgroundImage {
            fluid(
              maxWidth: 2800
              maxHeight: 1400
              imgixParams: { maxW: 2800, maxH: 1400, fit: "crop" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
          character {
            ultimate {
              name
              description
              thumbnail {
                gatsbyImageData(
                  placeholder: BLURRED
                  imgixParams: { w: "600", h: "200", fit: "crop" }
                )
              }
              video {
                video {
                  mp4Url
                }
              }
            }
            abilities {
              name
              icon {
                gatsbyImageData(
                  placeholder: BLURRED
                  imgixParams: { w: "300", h: "300", fit: "crop" }
                )
              }
              thumbnail {
                gatsbyImageData(
                  placeholder: BLURRED
                  imgixParams: { w: "600", h: "350", fit: "crop" }
                )
              }
              video {
                video {
                  mp4Url
                }
              }
              description
            }
            passiveAbilities {
              name
              icon {
                gatsbyImageData(
                  placeholder: BLURRED
                  imgixParams: { w: "300", h: "300", fit: "crop" }
                )
              }
              description
            }
          }
        }
        ... on DatoCmsCharacterCustomisation {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          character {
            costume {
              name
              rarity {
                name
                backgroundImage {
                  # fluid(
                  #     imgixParams: {
                  #         w: "1920"
                  #         h: "1080"
                  #         fit: "crop"
                  #     }
                  # ) {
                  fluid {
                    aspectRatio
                    base64
                    height
                    sizes
                    src
                    srcSet
                    width
                  }
                }
                colour {
                  hex
                  red
                  green
                  blue
                }
                textColor {
                  hex
                }
              }
              thumbnail {
                gatsbyImageData(
                  placeholder: BLURRED
                  imgixParams: { w: "340", h: "500", fit: "crop" }
                )
              }
              # video {
              #     video {
              #         mp4Url
              #     }
              # }
            }
            weaponWrap {
              name
              rarity {
                name
                backgroundImage {
                  # fluid(
                  #     imgixParams: {
                  #         w: "1920"
                  #         h: "1080"
                  #         fit: "crop"
                  #     }
                  # ) {
                  fluid {
                    aspectRatio
                    base64
                    height
                    sizes
                    src
                    srcSet
                    width
                  }
                }
                colour {
                  hex
                }
                textColor {
                  hex
                }
              }
              thumbnail {
                gatsbyImageData(
                  placeholder: BLURRED
                  imgixParams: { w: "340", h: "500", fit: "crop" }
                )
              }
            }
            victoryPose {
              name
              rarity {
                name
                backgroundImage {
                  # fluid(
                  #     imgixParams: {
                  #         w: "1920"
                  #         h: "1080"
                  #         fit: "crop"
                  #     }
                  # ) {
                  fluid {
                    aspectRatio
                    base64
                    height
                    sizes
                    src
                    srcSet
                    width
                  }
                }
                colour {
                  hex
                }
                textColor {
                  hex
                }
              }
              thumbnail {
                gatsbyImageData(
                  placeholder: BLURRED
                  imgixParams: { w: "340", h: "500", fit: "crop" }
                )
              }
            }
          }
        }
        ... on DatoCmsCharacterRelatedMediaShowcase {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          character {
            abilities {
              name
              icon {
                gatsbyImageData(
                  placeholder: BLURRED
                  imgixParams: { w: "300", h: "300", fit: "crop" }
                )
              }
              thumbnail {
                gatsbyImageData(
                  placeholder: BLURRED
                  imgixParams: { w: "600", h: "350", fit: "crop" }
                )
              }
            }
          }
        }

        ... on DatoCmsProgramOverview {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          tierHeading
          requirementsHeading
          benefitsHeading
          tiers {
            name
            benefits
            requirements
          }
          buttonHeading
          buttonText
          buttonLink {
            ... on DatoCmsPage {
              model {
                apiKey
              }
              slug
            }
            ... on DatoCmsCharacter {
              model {
                apiKey
              }
              slug
            }
            ... on DatoCmsPost {
              model {
                apiKey
              }
              slug
            }
          }
        }

        ... on DatoCmsTextWithMediaBlock {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          body
          image {
            gatsbyImageData(
              placeholder: BLURRED
              imgixParams: { w: "600", h: "350", fit: "crop" }
            )
          }
          video {
            video {
              mp4Url
            }
          }
        }

        ... on DatoCmsFaq {
          scrollToBladeId
          model {
            apiKey
          }
          heading
          buttonLabel
          questions {
            question
            answer
          }
        }
      }
    }
  }
`

const CharacterTemplate = ({
  data,
  pageContext: {
    recordTitle,
    globalConfig,
    locale,
    allPosts = [],
    allCharacters = [],
    classTypes = [],
    allArenas = [],
  },
  ...props
}) => {
  const blades = (data?.character?.blades || []).filter(
    (blade) => blade !== null
  )

  return (
    <Root {...{ globalConfig, locale, ...props }}>
      <Meta
        seo={{
          recordTitle,
          ...data?.character?.seo,
        }}
      />
      <Blades
        {...{
          blades,
          allPosts,
          allCharacters,
          currentCharacter: data.character,
          classTypes,
          allArenas,
          locale,
          globalConfig,
        }}
      />
    </Root>
  )
}

export default CharacterTemplate
